import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'

const Image = loadable(() => import('/src/components/Media/Image'))
const VideoPlayer = loadable(() => import('/src/components/Media/VideoPlayer'))

const ImageVideoSwap = ({ video, image, mediaType, className }) => {
    const classes = [...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    if (mediaType === 'image') {
        return <Image data={image} className={concatenatedClasses} />
    } else if (mediaType === 'video') {
        return <VideoPlayer data={video} />
    } else {
        return null
    }
}

ImageVideoSwap.propTypes = {
    /**
     * Data
     */
    data: PropTypes.shape({
        video: PropTypes.string,
        image: PropTypes.object
    }).isRequired,
    /**
     * Media type
     */
    mediaType: PropTypes.oneOf(['image', 'video']).isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

ImageVideoSwap.defaultProps = {
    data: {
        video: 'https://protect-line-marketing.wistia.com/medias/sce6ad8azy',
        image: {
            altText: 'Alt text',
            localFile: {
                childImageSharp: {
                    gatsbyImageData: {
                        layout: 'constrained',
                        placeholder: {
                            fallback:
                                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC3klEQVQoz12SyU9TURjFL0T/ArfG6MYpMewgaiIa7Ls0hKGkgi2DJEaF4gROEKD03ZaWlhZep3fv68BQKIUWFW2LEJyAgDjgxvgfuDUm1kRi6/0MRTd+yck5q9/iOwfFO4xfh7WmH76L1u/+iwMZqhvI+HXWjKQ1Zjza/oyvzpKRGxwZv97+zVvfD7HWenGw0olsFZ7CAbWngGBWQARaQDDNZ+TVDf9y6R/Cy/EtSLo3INq3DCnvG0h7N2HG9AIWlXcwbVyGpLTxW76SgGDTfdlWJZ8XBaWMYHqEYIaIQBHBO2IIsWbXtrN+EhKWZR7rW+DR7nkeJ4s8YV7kE3fn+ZxliY/fecwT5qWs//IURNtNfmslU4sqhgmmpwhmx4hAjxJMT4iYFSF2yfGz67QCVrWL+3UWLgoyxIzPIO1ZhZS0Agu+NYj1LcCSspENtM1AtLVHctQoxSYVO0swPUOEvBcRTNUEs3I0XGvP2muCfN3dC45qHxdxgCttcRjrnIfwzUcQeZCEYPscTHansu6mCZhuvScNVtESk4qd+wspJQJTEcyqiUAPoLhB82nd2QIRw2DubjGDqa6n/HXkA6xGt2A5tJn35+FNWJ3+mA0YZkFpNMi2SlYjCqySYCoQzOqIwIqIwHZ+uBd9CaGyV8aTYFIFuLncx92NEzx8M8FDN+IQbJ+F4PVZUK7FQGmdyUr6EMRv3Rke0vj3m1TKIYLpQSKwwwSzfbvlMIQiV2+joL7BvDMJ1mzdVlocOdo4mKONtpysN+dGtF05f4M5xxoGtgPNFpjrIOaRCxLqLIkgUdiF7MDEfy0fQZ/R2wqEekvl9Yn7KUhKa5CUViHlXoMXo+8h2rMInpYZCFx/DIrhEbh0087RjieIXo3vEQWW356I/0EZQraKocKOknkknvceH9KOLrjqx9Mj+khenuaptLNuLG2rCqbtmnDSrgmt2KoCl+yaMLLXhgstahn9f38AjIyqk89RRvIAAAAASUVORK5CYII='
                        },
                        backgroundColor: 'transparent',
                        images: {
                            fallback: {
                                src: '/static/6a48ba5874efd2c34ce7e92fccc3a151/5b636/award-protection.png',
                                srcSet: '/static/6a48ba5874efd2c34ce7e92fccc3a151/35c8d/award-protection.png 576w,\n/static/6a48ba5874efd2c34ce7e92fccc3a151/a83a0/award-protection.png 768w,\n/static/6a48ba5874efd2c34ce7e92fccc3a151/5b636/award-protection.png 800w',
                                sizes: '(min-width: 800px) 800px, 100vw'
                            },
                            sources: [
                                {
                                    srcSet: '/static/6a48ba5874efd2c34ce7e92fccc3a151/4b1ce/award-protection.avif 576w,\n/static/6a48ba5874efd2c34ce7e92fccc3a151/764c9/award-protection.avif 768w,\n/static/6a48ba5874efd2c34ce7e92fccc3a151/a279e/award-protection.avif 800w',
                                    type: 'image/avif',
                                    sizes: '(min-width: 800px) 800px, 100vw'
                                },
                                {
                                    srcSet: '/static/6a48ba5874efd2c34ce7e92fccc3a151/1b9c2/award-protection.webp 576w,\n/static/6a48ba5874efd2c34ce7e92fccc3a151/384d7/award-protection.webp 768w,\n/static/6a48ba5874efd2c34ce7e92fccc3a151/d8594/award-protection.webp 800w',
                                    type: 'image/webp',
                                    sizes: '(min-width: 800px) 800px, 100vw'
                                }
                            ]
                        },
                        width: 800,
                        height: 396
                    }
                }
            }
        }
    },
    mediaType: 'image'
}

export default ImageVideoSwap
